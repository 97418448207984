/* padding-bottom */
.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1  {
    padding-bottom: 1px !important;
}

.pb-2  {
    padding-bottom: 2px !important;
}

.pb-3  {
    padding-bottom: 3px !important;
}

.pb-4  {
    padding-bottom: 4px !important;
}

.pb-5  {
    padding-bottom: 5px !important;
}

.pb-6  {
    padding-bottom: 6px !important;
}

.pb-7  {
    padding-bottom: 7px !important;
}

.pb-8  {
    padding-bottom: 8px !important;
}

.pb-9  {
    padding-bottom: 9px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-11  {
    padding-bottom: 11px !important;
}

.pb-12  {
    padding-bottom: 12px !important;
}

.pb-13  {
    padding-bottom: 13px !important;
}

.pb-14  {
    padding-bottom: 14px !important;
}

.pb-15  {
    padding-bottom: 15px !important;
}

.pb-16  {
    padding-bottom: 16px !important;
}

.pb-17  {
    padding-bottom: 17px !important;
}

.pb-18  {
    padding-bottom: 18px !important;
}

.pb-19  {
    padding-bottom: 19px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-21  {
    padding-bottom: 21px !important;
}

.pb-22  {
    padding-bottom: 22px !important;
}

.pb-23  {
    padding-bottom: 23px !important;
}

.pb-24  {
    padding-bottom: 24px !important;
}

.pb-25  {
    padding-bottom: 25px !important;
}

.pb-26  {
    padding-bottom: 26px !important;
}

.pb-27  {
    padding-bottom: 27px !important;
}

.pb-28  {
    padding-bottom: 28px !important;
}

.pb-29  {
    padding-bottom: 29px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-31  {
    padding-bottom: 31px !important;
}

.pb-32  {
    padding-bottom: 32px !important;
}

.pb-33  {
    padding-bottom: 33px !important;
}

.pb-34  {
    padding-bottom: 34px !important;
}

.pb-35  {
    padding-bottom: 35px !important;
}

.pb-36  {
    padding-bottom: 36px !important;
}

.pb-37  {
    padding-bottom: 37px !important;
}

.pb-38  {
    padding-bottom: 38px !important;
}

.pb-39  {
    padding-bottom: 39px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-41  {
    padding-bottom: 41px !important;
}

.pb-42  {
    padding-bottom: 42px !important;
}

.pb-43  {
    padding-bottom: 43px !important;
}

.pb-44  {
    padding-bottom: 44px !important;
}

.pb-45  {
    padding-bottom: 45px !important;
}

.pb-46  {
    padding-bottom: 46px !important;
}

.pb-47  {
    padding-bottom: 47px !important;
}

.pb-48  {
    padding-bottom: 48px !important;
}

.pb-49  {
    padding-bottom: 49px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-51  {
    padding-bottom: 51px !important;
}

.pb-52  {
    padding-bottom: 52px !important;
}

.pb-53  {
    padding-bottom: 53px !important;
}

.pb-54  {
    padding-bottom: 54px !important;
}

.pb-55  {
    padding-bottom: 55px !important;
}

.pb-56  {
    padding-bottom: 56px !important;
}

.pb-57  {
    padding-bottom: 57px !important;
}

.pb-58  {
    padding-bottom: 58px !important;
}

.pb-59  {
    padding-bottom: 59px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-61  {
    padding-bottom: 61px !important;
}

.pb-62  {
    padding-bottom: 62px !important;
}

.pb-63  {
    padding-bottom: 63px !important;
}

.pb-64  {
    padding-bottom: 64px !important;
}

.pb-65  {
    padding-bottom: 65px !important;
}

.pb-66  {
    padding-bottom: 66px !important;
}

.pb-67  {
    padding-bottom: 67px !important;
}

.pb-68  {
    padding-bottom: 68px !important;
}

.pb-69  {
    padding-bottom: 69px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-71  {
    padding-bottom: 71px !important;
}

.pb-72  {
    padding-bottom: 72px !important;
}

.pb-73  {
    padding-bottom: 73px !important;
}

.pb-74  {
    padding-bottom: 74px !important;
}

.pb-75  {
    padding-bottom: 75px !important;
}

.pb-76  {
    padding-bottom: 76px !important;
}

.pb-77  {
    padding-bottom: 77px !important;
}

.pb-78  {
    padding-bottom: 78px !important;
}

.pb-79  {
    padding-bottom: 79px !important;
}
