/* padding */
.p-0 {
    padding: 0px !important;
}

.p-1  {
    padding: 1px !important;
}

.p-2  {
    padding: 2px !important;
}

.p-3  {
    padding: 3px !important;
}

.p-4  {
    padding: 4px !important;
}

.p-5  {
    padding: 5px !important;
}

.p-6  {
    padding: 6px !important;
}

.p-7  {
    padding: 7px !important;
}

.p-8  {
    padding: 8px !important;
}

.p-9  {
    padding: 9px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-11  {
    padding: 11px !important;
}

.p-12  {
    padding: 12px !important;
}

.p-13  {
    padding: 13px !important;
}

.p-14  {
    padding: 14px !important;
}

.p-15  {
    padding: 15px !important;
}

.p-16  {
    padding: 16px !important;
}

.p-17  {
    padding: 17px !important;
}

.p-18  {
    padding: 18px !important;
}

.p-19  {
    padding: 19px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-21  {
    padding: 21px !important;
}

.p-22  {
    padding: 22px !important;
}

.p-23  {
    padding: 23px !important;
}

.p-24  {
    padding: 24px !important;
}

.p-25  {
    padding: 25px !important;
}

.p-26  {
    padding: 26px !important;
}

.p-27  {
    padding: 27px !important;
}

.p-28  {
    padding: 28px !important;
}

.p-29  {
    padding: 29px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-31  {
    padding: 31px !important;
}

.p-32  {
    padding: 32px !important;
}

.p-33  {
    padding: 33px !important;
}

.p-34  {
    padding: 34px !important;
}

.p-35  {
    padding: 35px !important;
}

.p-36  {
    padding: 36px !important;
}

.p-37  {
    padding: 37px !important;
}

.p-38  {
    padding: 38px !important;
}

.p-39  {
    padding: 39px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-41  {
    padding: 41px !important;
}

.p-42  {
    padding: 42px !important;
}

.p-43  {
    padding: 43px !important;
}

.p-44  {
    padding: 44px !important;
}

.p-45  {
    padding: 45px !important;
}

.p-46  {
    padding: 46px !important;
}

.p-47  {
    padding: 47px !important;
}

.p-48  {
    padding: 48px !important;
}

.p-49  {
    padding: 49px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-51  {
    padding: 51px !important;
}

.p-52  {
    padding: 52px !important;
}

.p-53  {
    padding: 53px !important;
}

.p-54  {
    padding: 54px !important;
}

.p-55  {
    padding: 55px !important;
}

.p-56  {
    padding: 56px !important;
}

.p-57  {
    padding: 57px !important;
}

.p-58  {
    padding: 58px !important;
}

.p-59  {
    padding: 59px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-61  {
    padding: 61px !important;
}

.p-62  {
    padding: 62px !important;
}

.p-63  {
    padding: 63px !important;
}

.p-64  {
    padding: 64px !important;
}

.p-65  {
    padding: 65px !important;
}

.p-66  {
    padding: 66px !important;
}

.p-67  {
    padding: 67px !important;
}

.p-68  {
    padding: 68px !important;
}

.p-69  {
    padding: 69px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-71  {
    padding: 71px !important;
}

.p-72  {
    padding: 72px !important;
}

.p-73  {
    padding: 73px !important;
}

.p-74  {
    padding: 74px !important;
}

.p-75  {
    padding: 75px !important;
}

.p-76  {
    padding: 76px !important;
}

.p-77  {
    padding: 77px !important;
}

.p-78  {
    padding: 78px !important;
}

.p-79  {
    padding: 79px !important;
}
