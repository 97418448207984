.primary-color {
    color: #154398 !important;
}

.sub-color {
    color: #838383;
}

.black-color {
    color: #000000;
}

.red-star {
    color: red;
    margin-left: 2px;
}

.white {
    color: white;
}

.content-cl {
    color: #838383;
}

.linked-cl {
    // font-family: "Fira Sans", "Roboto", "Poppins", sans-serif;
    color: #154398;
    font-weight: 600;
}

.blue-header {
    background: #eaf3f7 !important;
}