@mixin setLabel($colorLabel) {
  top: -0.4em;
  font-size: 12px !important;
  z-index: 3;
  color: $colorLabel;
}

.fieldWrapper {
  display: block;
  position: relative;
  border-radius: 8px;

  input {
    z-index: 3;
  }

  label {
    position: absolute;
    cursor: text;
    font-size: 14px !important;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    top: 1em;
    left: 0.75rem;
    z-index: 0;
    line-height: 10px;
    padding: 0 1px;
    color: #868e96;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      height: 1px;
      top: 50%;
      left: -0.1em;
      right: -0.2em;
      z-index: -1;
    }
  }

  .labelFocus {
    @include setLabel(#0d99ff);
  }

  .selectHasValue {
    @include setLabel(#222d4b);
  }
}
