@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Fira Sans", "Roboto", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ant-scrolling-effect {
  width: calc(100% - 5px) !important;
}
.ant-modal-body {
  flex: auto;
  overflow: hidden auto;
}
.ant-modal-content {
  max-height: calc(100vh - 20px);
  flex-direction: column;
  display: flex;
}
.left-content-admin {
  width: 300px;
}
.not-allowed {
  cursor: not-allowed;
}
.visible-hidden {
  visibility: hidden !important;
}
.cb1 .ant-modal-confirm-btns {
  justify-content: center;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 96%;
}
.editable-row .ant-form-item-explain-error {
  font-size: 12px;
}
.right-content-admin {
  width: calc(100% - 300px);
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: unset;
  margin: 5px 0;
}

.ant-table .ant-table-tbody > tr.ant-table-row:hover .list-button-hover {
  display: flex;
}

.list-button-hover {
  display: none;
}
.loading-modal {
  z-index: 2000;
}
.nowrap {
  flex-wrap: nowrap;
}
.breadcrumb {
  box-shadow: inset 0 5px 10px #ebebeb;
  padding: 15px 0;
  background: #f7f7f7;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.ant-picker-cell-inner {
  min-height: 24px;
  height: unset !important;
}
.text-uppercase {
  text-transform: uppercase;
}

.flex-column {
  flex-direction: column;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: flex !important;
}
.ant-pagination-item-link {
  border: 1px solid #e9ebec !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
  width: 32px;
  background: #fff !important;
}
.ant-pagination-item {
  border: 1px solid #e9ebec !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
}
.ant-pagination-item a {
  font-weight: 600;
  font-size: 14px;
  color: #6a7688;
}
.ant-pagination-item-active {
  background: #2260bd;
}
.ant-pagination-item-active a {
  color: #ffffff;
}
.ant-pagination-options .ant-select .ant-select-selector {
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
  border: 1px solid #e9ebec;
  height: 32px !important;
  align-items: center;
}
.ant-pagination-options
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  font-weight: 600;
  font-size: 14px;

  color: #6a7688;
}

.ant-modal {
  padding-bottom: 0;
}
.sub-column-table {
  font-size: 13px;
  color: #666666;
  font-style: italic;
}

.ant-input-number_no_arrow > .ant-input-number-handler-wrap {
  display: none;
}

.title-type-1 {
  font-size: 24px;
  color: #134197;
  font-family: "Inter Bold", sans-serif;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  font-weight: 700;
  margin-bottom: 30px;
}
.title-type-1-admin {
  font-size: 24px;
  color: #134197;
  font-family: "Inter Bold", sans-serif;
  padding: 12px 0;
  border-bottom: 1px solid #ebebeb;
  position: relative;
  font-weight: 700;
  margin-bottom: 15px;
}
.title-type-2 {
  font-size: 24px;
  color: #134197;
  font-family: "Inter Bold", sans-serif;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  font-weight: 700;
  position: relative;
}
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-type-2::after {
  content: "";
  width: 80px;
  height: 4px;
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(21, 67, 152) 0%,
    rgb(238, 29, 35) 100%
  );
  position: absolute;
  left: 40px;
  bottom: -2px;
  margin: 0 0 0 -40px;
}
.title-type-1::after {
  content: "";
  width: 80px;
  height: 4px;
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(21, 67, 152) 0%,
    rgb(238, 29, 35) 100%
  );
  position: absolute;
  left: 40px;
  bottom: -2px;
  margin: 0 0 0 -40px;
}
body::-webkit-scrollbar-thumb {
  background: #c5ced9;
  border-radius: 30px;
}

* { 
  font-family: "Inter", sans-serif;
  -webkit-overflow-scrolling: touch;
}
.layout-center {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-primary svg path {
  fill: #154398;
}
.layout-max-width {
  max-width: 100vw;
  width: 100vw;
}
@media only screen and (min-width: 2000px) {
  .layout-max-width {
    max-width: 1090vw;
  }
}
.ant-breadcrumb-link {
  font-size: 12px;
}
.ant-breadcrumb li:last-child {
  font-weight: 600;
}
.w-100 {
  width: 100%;
}
.w-100vh {
  width: 100vh;
}

.title-header-page {
  font-weight: 600;
  font-size: 24px;
  color: #154398;
  margin: 30px 0px;
}
.rotate0 {
  transform: rotate(0deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate270 {
  transform: rotate(270deg);
}
.rotate360 {
  transform: rotate(360deg);
}
.ant-form-item-label {
  font-weight: 600;
}
.ant-form-item {
  margin-bottom: 20px;
}
.fs-16 {
  font-size: 16px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.mt-0 {
  margin-top: 0px !important;
}
.fs-20 {
  font-size: 22px;
}
.fs-22 {
  font-size: 22px;
}
.h-100 {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}
.primary-color {
  color: #154398 !important;
}
.sub-color {
  color: #838383;
}
.black-color {
  color: #000000;
}
.pointer {
  cursor: pointer;
}
.center {
  display: flex;
  align-items: center;
}
.text-underline {
  text-decoration: underline;
}
.pv-16 {
  padding: 16px 0px;
}
.mv-16 {
  margin: 16px 0px;
}
.mv-10 {
  margin: 10px 0px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-ellipsis-2 {
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}
.text-ellipsis-3 {
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}
.text-ellipsis-4 {
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
}
.text-ellipsis-5 {
  -webkit-line-clamp: 5 !important; /* number of lines to show */
  line-clamp: 5 !important;
}
.d-flex {
  display: flex !important;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600 !important;
}
.v-hidden {
  visibility: hidden;
}
.align-item-center {
  align-items: center;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.d-none {
  display: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border: unset;
}
/* .ant-modal-wrap {
  z-index: 1099;
}
.ant-notification {
  z-index: 1100;
} */

.red-star {
  color: red;
  margin-left: 2px;
}

.btn-linear-1 {
  height: 42px !important;
  padding: 0px 30px;
}

.btn-linear-2 {
  height: 45px !important;
  padding: 0px 30px;
}
.mr-14 {
  margin-right: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.m-16 {
  margin: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.m-22 {
  margin: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.m-24 {
  margin: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mt-40 {
  margin-top: 40px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

/* padding */
.p-0 {
  padding: 0px !important;
}
.p-16 {
  padding: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.ant-table-thead::first-letter,
.ant-table-cell::first-letter {
  text-transform: capitalize;
}
.fs-24 {
  font-size: 24px;
}
.destroy-close-icon .ant-notification-notice-close {
  display: none;
}
.price-number {
  background: rgba(0, 197, 144, 0.1);
  border-radius: 4px;
  padding: 3px 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  /* main color */

  color: #154398;
}
.pl-16 {
  padding-left: 16px !important;
}
.ant-input-number {
  width: 100% !important;
  border-radius: 8px !important;
}
.ant-input-number-input {
  height: 37px !important;
}
.mh-36 {
  min-height: 36px;
}
.pt-16 {
  padding-top: 16px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.u-p-32 {
  padding: 32px !important;
}

.u-pr-32 {
  padding-right: 32px !important;
}

.u-pl-32 {
  padding-left: 32px !important;
}

.u-pt-32 {
  padding-top: 32px !important;
}

.u-pb-32 {
  padding-bottom: 32px !important;
}

.u-m-32 {
  margin: 32px !important;
}

.u-mr-32 {
  margin-right: 32px !important;
}

.u-ml-32 {
  margin-left: 32px !important;
}

.u-mt-32 {
  margin-top: 32px !important;
}

.u-mb-32 {
  margin-bottom: 32px !important;
}

.u-m-35 {
  margin: 35px !important;
}

.u-mr-35 {
  margin-right: 35px !important;
}

.u-ml-35 {
  margin-left: 35px !important;
}

.u-mt-35 {
  margin-top: 35px !important;
}

.u-mb-35 {
  margin-bottom: 35px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.btn-not-background {
  font-weight: 600;
  color: #154398;
  background: #ffffff;
  border: 1px solid #154398;
  border-radius: 4px;
}
.max-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max-line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important; /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical;
}
.max-line4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important; /* number of lines to show */
  line-clamp: 4 !important;
  -webkit-box-orient: vertical;
}
.max-line5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5 !important; /* number of lines to show */
  line-clamp: 5 !important;
  -webkit-box-orient: vertical;
}

.max-post {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7 !important; /* number of lines to show */
  line-clamp: 7 !important;
  -webkit-box-orient: vertical;
}

.number-comment {
  font-weight: 400;
  font-size: 12px;
  color: #154398;
}

.title-table {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
  color: #000000;
}

.scroll-modalcuston {
  flex: auto;
  overflow: hidden auto;
  max-height: calc(100vh - 232px);
  padding-right: 4px;
}

.scroll-modalcuston2 {
  flex: auto;
  overflow: hidden auto;
  max-height: calc(100vh - 232px);
  padding-right: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.is-news {
  color: #ff5959 !important;
  /* font-weight: 600; */
}

.btn-hover:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  background: #fff !important;
  color: #1d54b9 !important;
  border: 2px solid #1d54b9;
}
.hover-shadow:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}
.btn-hover-shadow:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.bdr-8 {
  border-radius: 8px;
}
.hover-menu:hover {
  color: #ff5959 !important;
}
.tiny-in-modal-step .tox-fullscreen {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  height: auto !important;
  width: 25% !important;
}
/* .tox-tinymce {
  height: 70vh !important;
} */
.tox-tinymce.tox-fullscreen {
  height: 100vh !important;
}
