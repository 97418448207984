/* margin-bottom */
.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1  {
    margin-bottom: 1px !important;
}

.mb-2  {
    margin-bottom: 2px !important;
}

.mb-3  {
    margin-bottom: 3px !important;
}

.mb-4  {
    margin-bottom: 4px !important;
}

.mb-5  {
    margin-bottom: 5px !important;
}

.mb-6  {
    margin-bottom: 6px !important;
}

.mb-7  {
    margin-bottom: 7px !important;
}

.mb-8  {
    margin-bottom: 8px !important;
}

.mb-9  {
    margin-bottom: 9px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-11  {
    margin-bottom: 11px !important;
}

.mb-12  {
    margin-bottom: 12px !important;
}

.mb-13  {
    margin-bottom: 13px !important;
}

.mb-14  {
    margin-bottom: 14px !important;
}

.mb-15  {
    margin-bottom: 15px !important;
}

.mb-16  {
    margin-bottom: 16px !important;
}

.mb-17  {
    margin-bottom: 17px !important;
}

.mb-18  {
    margin-bottom: 18px !important;
}

.mb-19  {
    margin-bottom: 19px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-21  {
    margin-bottom: 21px !important;
}

.mb-22  {
    margin-bottom: 22px !important;
}

.mb-23  {
    margin-bottom: 23px !important;
}

.mb-24  {
    margin-bottom: 24px !important;
}

.mb-25  {
    margin-bottom: 25px !important;
}

.mb-26  {
    margin-bottom: 26px !important;
}

.mb-27  {
    margin-bottom: 27px !important;
}

.mb-28  {
    margin-bottom: 28px !important;
}

.mb-29  {
    margin-bottom: 29px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-31  {
    margin-bottom: 31px !important;
}

.mb-32  {
    margin-bottom: 32px !important;
}

.mb-33  {
    margin-bottom: 33px !important;
}

.mb-34  {
    margin-bottom: 34px !important;
}

.mb-35  {
    margin-bottom: 35px !important;
}

.mb-36  {
    margin-bottom: 36px !important;
}

.mb-37  {
    margin-bottom: 37px !important;
}

.mb-38  {
    margin-bottom: 38px !important;
}

.mb-39  {
    margin-bottom: 39px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-41  {
    margin-bottom: 41px !important;
}

.mb-42  {
    margin-bottom: 42px !important;
}

.mb-43  {
    margin-bottom: 43px !important;
}

.mb-44  {
    margin-bottom: 44px !important;
}

.mb-45  {
    margin-bottom: 45px !important;
}

.mb-46  {
    margin-bottom: 46px !important;
}

.mb-47  {
    margin-bottom: 47px !important;
}

.mb-48  {
    margin-bottom: 48px !important;
}

.mb-49  {
    margin-bottom: 49px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-51  {
    margin-bottom: 51px !important;
}

.mb-52  {
    margin-bottom: 52px !important;
}

.mb-53  {
    margin-bottom: 53px !important;
}

.mb-54  {
    margin-bottom: 54px !important;
}

.mb-55  {
    margin-bottom: 55px !important;
}

.mb-56  {
    margin-bottom: 56px !important;
}

.mb-57  {
    margin-bottom: 57px !important;
}

.mb-58  {
    margin-bottom: 58px !important;
}

.mb-59  {
    margin-bottom: 59px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-61  {
    margin-bottom: 61px !important;
}

.mb-62  {
    margin-bottom: 62px !important;
}

.mb-63  {
    margin-bottom: 63px !important;
}

.mb-64  {
    margin-bottom: 64px !important;
}

.mb-65  {
    margin-bottom: 65px !important;
}

.mb-66  {
    margin-bottom: 66px !important;
}

.mb-67  {
    margin-bottom: 67px !important;
}

.mb-68  {
    margin-bottom: 68px !important;
}

.mb-69  {
    margin-bottom: 69px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-71  {
    margin-bottom: 71px !important;
}

.mb-72  {
    margin-bottom: 72px !important;
}

.mb-73  {
    margin-bottom: 73px !important;
}

.mb-74  {
    margin-bottom: 74px !important;
}

.mb-75  {
    margin-bottom: 75px !important;
}

.mb-76  {
    margin-bottom: 76px !important;
}

.mb-77  {
    margin-bottom: 77px !important;
}

.mb-78  {
    margin-bottom: 78px !important;
}

.mb-79  {
    margin-bottom: 79px !important;
}
