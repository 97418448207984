@import "./assets/fonts/icon-font/style.css";

:root {
  --color-primary: #0d99ff;
  --color-blue-dark: #059669;
  --color-green-500: #10b981;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --text-primary: #4a4a4a;
  --text-white: #fff;
  --text-landing-page: #000;
  --color-landing-page: #fff;
  --color-landing-page-divider: #6e6e6e;
  --color-landing-page-header:#ffffff;
  --color-landing-page-box-time:#ffc805;
  // --color-landing-page-header:#002568;
  // --color-landing-page: #154398;
}
.ant-form-item-explain-error {
  color: #f5222d;
  font-size: 12px;
}

* {
  box-sizing: border-box;
  padding: 0;
}
.abc123456 {
  text-align: center;
  text-decoration-line: underline;
  font-size: 24px;
  cursor: pointer;
  color: #0d99ff;
}
.app {
  width: 100%;
  height: 100vh;
  background-color: #fafaff;
  display: flex;
  flex-direction: row;
}

.action {
  width: 80vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
}

.addItemContainer {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #aaa;
}

.image-delete {
  cursor: pointer;
}
.image-delete svg {
  width: 20px;
  height: 20px;
}
.filterItemContainer {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addItemText {
  display: block;
  margin-right: 20px;
}

.addItemInput {
  height: 30px;
  margin-right: 20px;
}

.addInputButton {
  width: 60px;
  height: 30px;
  cursor: pointer;
}

.displayListContainer {
  border-left: 1px solid #aaa;
  height: 80%;
  width: 20vw;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.displayListContainerEmpty {
  border-left: 1px solid #aaa;
  height: 100%;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listItem {
  display: block;
  width: 100%;
}

.resetContainer {
  border-left: 1px solid #aaa;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayListContainerMain {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item-shadow {
  background: #fff;
  box-shadow: 2px 4px 11px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.tabs-red-home {
  .ant-tabs-nav {
    margin: unset;
  }
  .ant-tabs-tab-active {
    // background: #E06651 !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    // .ant-tabs-tab-btn,.lib-children {
    //   color: #fff !important;
    // }
  }
  .ant-tabs-ink-bar {
    height: 0px !important;
    // background: #E06651 !important;
  }
}

.text-input-area-disable {
  .ant-input-disabled {
    background: transparent !important;
    color: #000 !important;
    height: 220px;
    overflow: hidden auto;
    resize: none;
    border: none;
    padding: 0;
  }
}